import CircleIcon from '@mui/icons-material/CircleRounded';
import { memo } from '../../../util/memo';
import {
  useLivestreamConfigurationDialog,
  UseLivestreamConfigurationDialogParams,
} from '../../../hooks/livestream/useLivestreamConfigurationDialog';
import Button from '@mui/material/Button';

export type LivestreamConfigurationButtonProps =
  UseLivestreamConfigurationDialogParams;

const LivestreamConfigurationButtonUnmemoized = ({
  channelId,
  onClose,
}: LivestreamConfigurationButtonProps) => {
  const { open } = useLivestreamConfigurationDialog({
    channelId,
    onClose,
  });

  return (
    <Button
      onClick={open}
      variant="contained"
      color="error"
      sx={{ height: '32px', whiteSpace: 'nowrap' }}
      startIcon={
        <CircleIcon
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          color={'text.primary' as any}
          sx={{ width: '12px', height: '12px' }}
        />
      }
    >
      Go Live
    </Button>
  );
};

export const LivestreamConfigurationButton = memo(
  LivestreamConfigurationButtonUnmemoized,
);
